var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown is-hoverable is-right has-text-left" },
    [
      _c(
        "div",
        { staticClass: "dropdown-trigger" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-danger",
                "icon-right": "fa fa-angle-down",
                "icon-left": "fas fa-download",
              },
            },
            [_vm._v(" Export to Excel ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu", attrs: { role: "menu" } }, [
        _c("div", { staticClass: "dropdown-content" }, [
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: _vm.clan.tag + ".xlsx" },
              on: {
                click: function ($event) {
                  return _vm.download($event, 0)
                },
              },
            },
            [_vm._v(" Today ")]
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: _vm.clan.tag + ".xlsx" },
              on: {
                click: function ($event) {
                  return _vm.download($event, 1)
                },
              },
            },
            [_vm._v(" Yesterday ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: _vm.clan.tag + ".xlsx" },
              on: {
                click: function ($event) {
                  return _vm.download($event, 2)
                },
              },
            },
            [_vm._v(" Two Days Ago ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: _vm.clan.tag + ".xlsx" },
              on: {
                click: function ($event) {
                  return _vm.download($event, 3)
                },
              },
            },
            [_vm._v(" Three Days Ago ")]
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: _vm.clan.tag + ".xlsx" },
              on: {
                click: function ($event) {
                  return _vm.download($event, 7)
                },
              },
            },
            [_vm._v(" Last Week ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }