var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns" }, [
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "columns is-gapless is-vcentered" },
        [
          _c(
            "b-field",
            { staticClass: "column is-narrow" },
            [
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": 1, type: "is-danger" },
                  model: {
                    value: _vm.days,
                    callback: function ($$v) {
                      _vm.days = $$v
                    },
                    expression: "days",
                  },
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "hourglass", size: "is-small", pack: "far" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Yesterday")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-radio-button",
                {
                  attrs: { "native-value": 7, type: "is-success" },
                  model: {
                    value: _vm.days,
                    callback: function ($$v) {
                      _vm.days = $$v
                    },
                    expression: "days",
                  },
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "calendar-alt",
                      size: "is-small",
                      pack: "far",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Last Week")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { staticClass: "column is-hidden-mobile" },
            [
              _c(
                "b-switch",
                {
                  attrs: { "true-value": "delta", "false-value": "value" },
                  model: {
                    value: _vm.sort,
                    callback: function ($$v) {
                      _vm.sort = $$v
                    },
                    expression: "sort",
                  },
                },
                [_vm._v(" Sort by difference ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column is-narrow is-hidden-mobile" },
      [
        _c(
          "b-dropdown",
          {
            attrs: { multiple: "", "aria-role": "list", triggers: ["hover"] },
            scopedSlots: _vm._u([
              {
                key: "trigger",
                fn: function () {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "is-primary",
                          "icon-right": "fa fa-angle-down",
                          "icon-left": "fas fa-columns",
                        },
                      },
                      [_vm._v(" Columns ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.selectedGroups,
              callback: function ($$v) {
                _vm.selectedGroups = $$v
              },
              expression: "selectedGroups",
            },
          },
          [
            _vm._v(" "),
            _vm._l(_vm.allGroups, function (group) {
              return _c(
                "b-dropdown-item",
                {
                  key: group,
                  attrs: { value: group, "aria-role": "listitem" },
                },
                [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-left" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "media-content" }, [
                      _c("h3", { staticClass: "is-capitalized" }, [
                        _vm._v(_vm._s(group)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("download-button"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }