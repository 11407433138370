var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tweeningValue != null
    ? _c(
        "span",
        {
          class:
            ((_obj = {}),
            (_obj[_vm.positiveClass] = _vm.tweeningValue > 0),
            (_obj[_vm.negativeClass] = _vm.tweeningValue < 0),
            _obj),
        },
        [
          _vm.showPlusSign && _vm.tweeningValue > 0 ? [_vm._v("+")] : _vm._e(),
          _vm._v(
            _vm._s(
              _vm.tweeningValue.toLocaleString(undefined, _vm.localeStyle)
            ) + "\n"
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }