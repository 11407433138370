import { render, staticRenderFns } from "./ClanPageHeader.vue?vue&type=template&id=9ff6d81a&scoped=true&"
import script from "./ClanPageHeader.vue?vue&type=script&lang=js&"
export * from "./ClanPageHeader.vue?vue&type=script&lang=js&"
import style0 from "./ClanPageHeader.vue?vue&type=style&index=0&id=9ff6d81a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@5.2.7_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff6d81a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ff6d81a')) {
      api.createRecord('9ff6d81a', component.options)
    } else {
      api.reload('9ff6d81a', component.options)
    }
    module.hot.accept("./ClanPageHeader.vue?vue&type=template&id=9ff6d81a&scoped=true&", function () {
      api.rerender('9ff6d81a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/ClanPageHeader.vue"
export default component.exports