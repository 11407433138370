var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns is-vcentered is-mobile" }, [
    _c("div", { staticClass: "column has-text-right" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "column is-size-1 has-text-weight-light",
        class: _vm.gradeColor(_vm.score),
      },
      [_vm._v("\n    " + _vm._s(_vm._f("grade")(_vm.score)) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }