var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-table",
        {
          ref: "table",
          attrs: { striped: "", "mobile-cards": "", data: _vm.cwlData },
        },
        [
          _c("b-table-column", {
            attrs: { field: "name", label: "Name", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v("\n      " + _vm._s(props.row.name) + "\n    "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: { field: "tag", label: "Tag", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v("\n      " + _vm._s(props.row.tag) + "\n    ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm._l([1, 2, 3, 4, 5, 6, 7], function (i) {
            return _c("b-table-column", {
              key: "stars" + i,
              attrs: {
                field: "stars_day_" + i,
                label: "Day " + i,
                numeric: "",
                sortable: "",
                centered: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "stars",
                            class: "stars_" + props.row["stars_day_" + i],
                          },
                          [
                            props.row["stars_day_" + i] != "na"
                              ? _c("b-rate", {
                                  attrs: {
                                    "icon-pack": "fa",
                                    icon: "star",
                                    max: 3,
                                    disabled: "",
                                  },
                                  model: {
                                    value: props.row["stars_day_" + i],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, "stars_day_" + i, $$v)
                                    },
                                    expression: "props.row[`stars_day_${i}`]",
                                  },
                                })
                              : _c("span", [_vm._v("—")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "stars_avg",
              label: "Stars Average",
              numeric: "",
              sortable: "",
              centered: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          props.row.stars_avg.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        ) +
                        "\n    "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm._l([1, 2, 3, 4, 5, 6, 7], function (i) {
            return _c("b-table-column", {
              key: "destruction" + i,
              attrs: {
                field: "destruction_day_" + i,
                label: "Day " + i,
                numeric: "",
                sortable: "",
                centered: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              props.row["destruction_day_" + i] != "na"
                                ? Number(
                                    props.row["destruction_day_" + i] / 100
                                  ).toLocaleString(undefined, {
                                    style: "percent",
                                    minimumFractionDigits: 0,
                                  })
                                : "—"
                            ) +
                            "\n    "
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _c("b-table-column", {
            attrs: {
              field: "destruction_avg",
              label: "Destruction Average",
              numeric: "",
              sortable: "",
              centered: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          Number(
                            props.row.destruction_avg / 100
                          ).toLocaleString(undefined, {
                            style: "percent",
                            minimumFractionDigits: 1,
                          })
                        ) +
                        "\n    "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }