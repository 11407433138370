var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hero-body" }, [
    _c("div", { staticClass: "container is-fluid" }, [
      _c("div", { staticClass: "columns is-multiline is-centered" }, [
        _c(
          "div",
          { staticClass: "column is-12-tablet is-9-desktop is-10-widescreen" },
          [
            _c(
              "div",
              { staticClass: "columns is-vcentered has-text-centered-mobile" },
              [
                _c("div", { staticClass: "column is-narrow" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.clan.badgeUrls.large,
                      width: "180",
                      height: "180",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("h1", { staticClass: "title is-marginless" }, [
                        _vm._v(_vm._s(_vm.clan.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "has-text-weight-light" },
                        [_c("last-updated")],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns mt-2 mb-0" }, [
                        _c(
                          "div",
                          { staticClass: "column is-narrow pt-0 pb-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "tags is-centered" },
                              [
                                _c("clan-tag", {
                                  attrs: { value: _vm.clan.tag },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: "Members",
                                      position: "is-right",
                                      type: "is-dark",
                                      delay: 350,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tag is-light" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-user-friends mr-1",
                                        }),
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.clan.members) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: "Clan Level",
                                      position: "is-right",
                                      type: "is-dark",
                                      delay: 350,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tag is-light" },
                                      [
                                        _vm._v(
                                          "Level " + _vm._s(_vm.clan.clanLevel)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.clan.location.isCountry
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "tag is-light",
                                        attrs: {
                                          href:
                                            "/country/" +
                                            _vm.clan.location.countryCode.toLowerCase(),
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "flag-icon mr-1",
                                          class:
                                            "flag-icon-" +
                                            _vm.clan.location.countryCode.toLowerCase(),
                                        }),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.clan.location.name) +
                                            "\n                      "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: "Clan War League",
                                      position: "is-right",
                                      type: "is-dark",
                                      delay: 350,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "tag is-light" },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(_vm.clan.warLeague.name) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns mt-2 mb-0" }, [
                        _c(
                          "div",
                          { staticClass: "column is-narrow pt-0 pb-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "tags is-centered" },
                              _vm._l(_vm.clan.labels, function (label) {
                                return _c(
                                  "span",
                                  {
                                    key: label.id,
                                    staticClass: "tag is-danger is-light",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        src: label.iconUrls.small,
                                        width: "20",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(label.name) +
                                        "\n                      "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "subtitle is-6 has-text-weight-light",
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "subtitle clan-description",
              domProps: { innerHTML: _vm._s(_vm.clan.richDescription) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "level" }, [
              _c("div", { staticClass: "level-item has-text-centered" }, [
                _c("div", [
                  _c("p", { staticClass: "heading" }, [
                    _vm._v("Total Trophies"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "title has-text-weight-light" },
                    [
                      _c("clan-field", {
                        attrs: { value: _vm.clan.clanPoints },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-item has-text-centered" }, [
                _c("div", [
                  _c("p", { staticClass: "heading" }, [
                    _vm._v("Total BH Trophies"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "title has-text-weight-light" },
                    [
                      _c("clan-field", {
                        attrs: { value: _vm.clan.clanVersusPoints },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-item has-text-centered" }, [
                _c("div", [
                  _c("p", { staticClass: "heading" }, [_vm._v("Donations")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "title has-text-weight-light" },
                    [
                      _c("clan-field", {
                        attrs: { value: _vm.clan.computed.totalDonations },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-item has-text-centered" }, [
                _c("div", [
                  _c("p", { staticClass: "heading" }, [_vm._v("War Wins")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "title has-text-weight-light" },
                    [_c("clan-field", { attrs: { value: _vm.clan.warWins } })],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-item has-text-centered" }, [
                _c("div", [
                  _c("p", { staticClass: "heading" }, [
                    _vm._v("War Win Ratio"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "title has-text-weight-light" },
                    [
                      _c("clan-field", {
                        attrs: {
                          value: _vm.clan.warWinRatio,
                          "locale-style": { style: "percent" },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "has-text-centered is-touch-only" }, [
              _c(
                "a",
                {
                  staticClass: "button is-info",
                  attrs: {
                    href:
                      "clashofclans://action=OpenClanProfile&tag=" +
                      _vm.clan.tag,
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", [_vm._v("Open Clan in Game")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "is-hidden-mobile" },
              [_c("trophy-chart")],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column is-5-tablet is-3-desktop is-2-widescreen" },
          [
            _c("div", { staticClass: "panel is-primary scorecard" }, [
              _c("h3", { staticClass: "panel-heading has-text-centered" }, [
                _vm._v("Clan Scorecard™"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-block" },
                [
                  _c("clan-score", {
                    attrs: {
                      title: "Clan War League",
                      field: "monthDelta.avgCwlStarsPercentile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-block" },
                [
                  _c("clan-score", {
                    attrs: {
                      title: "Clan Games",
                      field: "monthDelta.avgGamesXpPercentile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-block" },
                [
                  _c("clan-score", {
                    attrs: {
                      title: "Clan Wars",
                      field: "weekDelta.avgWarStarsPercentile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-block" },
                [
                  _c("clan-score", {
                    attrs: {
                      title: "Donations",
                      field: "weekDelta.avgDonationsPercentile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-block" },
                [
                  _c("clan-score", {
                    attrs: {
                      title: "Attacks Won",
                      field: "weekDelta.avgAttackWinsPercentile",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-external-link-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }