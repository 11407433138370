var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.clan.comparableMembers
        ? _c(
            "b-table",
            {
              ref: "table",
              attrs: {
                data: _vm.tableData,
                striped: "",
                hoverable: "",
                "mobile-cards": "",
                "detail-key": "id",
                detailed: "",
                "default-sort": "currentTrophies.value",
                "default-sort-direction": "desc",
                "opened-detailed": _vm.openDetails,
              },
              on: {
                "details-open": function (row) {
                  return _vm.gaEvent(
                    "open-player-details",
                    "Click Player Details",
                    "Player Tag",
                    row.tag.value
                  )
                },
                sort: function (column) {
                  return _vm.gaEvent(
                    "sort-players",
                    "Sort Column",
                    "Column",
                    column
                  )
                },
                click: _vm.onRowClicked,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "detail",
                    fn: function (props) {
                      return [
                        _c("player-comparison", {
                          attrs: { "player-data": props.row },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                1852988084
              ),
            },
            _vm._l(
              Object.keys(_vm.clan.comparableMembers.header),
              function (key) {
                return _c("b-table-column", {
                  key: key,
                  attrs: {
                    label: _vm.clan.comparableMembers.header[key],
                    field: key + "." + _vm.sortField,
                    numeric: _vm.isNumeric(key),
                    sortable: "",
                    visible: _vm.isVisible(key),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _vm._v(
                              "\n      " +
                                _vm._s(
                                  _vm.formatNumber(key, props.row[key].value)
                                ) +
                                "\n      "
                            ),
                            key == "name" &&
                            _vm.clan.playerStatus[props.row.tag.value]
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "tag is-uppercase",
                                    class:
                                      _vm.clan.playerStatus[
                                        props.row.tag.value
                                      ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.clan.playerStatus[
                                          props.row.tag.value
                                        ]
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNumeric(key) && props.row[key].delta != 0
                              ? _c(
                                  "b",
                                  {
                                    key: props.row[key].delta,
                                    class: {
                                      up: props.row[key].delta > 0,
                                      down: props.row[key].delta < 0,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "fa-sm fa",
                                      class: {
                                        "fa-caret-up": props.row[key].delta > 0,
                                        "fa-caret-down":
                                          props.row[key].delta < 0,
                                      },
                                    }),
                                    _vm._v(
                                      "\n        " +
                                        _vm._s(
                                          _vm.formatNumber(
                                            key,
                                            props.row[key].delta
                                          )
                                        ) +
                                        "\n      "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }
            ),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("notification"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }