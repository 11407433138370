var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        { staticClass: "hero is-warning" },
        [_c("clan-page-header")],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tabs is-centered is-boxed" }, [
        _c(
          "ul",
          [
            _c("router-link", {
              attrs: { to: { name: "players" }, custom: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "", isExactActive && "is-active"],
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: href }, on: { click: navigate } },
                            [_vm._v("Members")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.clan.recentCwlGroup
              ? _c("router-link", {
                  attrs: { to: { name: "cwl" }, custom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var href = ref.href
                          var navigate = ref.navigate
                          var isActive = ref.isActive
                          var isExactActive = ref.isExactActive
                          return [
                            _c(
                              "li",
                              {
                                class: [
                                  isActive && "",
                                  isExactActive && "is-active",
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: href },
                                    on: { click: navigate },
                                  },
                                  [_vm._v("Clan League Wars")]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1808186307
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.clan.wars.length
              ? _c("router-link", {
                  attrs: { to: { name: "wars" }, custom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var href = ref.href
                          var navigate = ref.navigate
                          var isActive = ref.isActive
                          var isExactActive = ref.isExactActive
                          return [
                            _c(
                              "li",
                              {
                                class: [
                                  isActive && "",
                                  isExactActive && "is-active",
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: href },
                                    on: { click: navigate },
                                  },
                                  [_vm._v("Clan Wars")]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1499380636
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("keep-alive", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }