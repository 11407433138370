var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "tools is-hidden-mobile" },
        [_c("table-nav")],
        1
      ),
      _vm._v(" "),
      _c("clan-table"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }