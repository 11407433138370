import { render, staticRenderFns } from "./ClanPage.vue?vue&type=template&id=669a6af8&scoped=true&"
import script from "./ClanPage.vue?vue&type=script&lang=js&"
export * from "./ClanPage.vue?vue&type=script&lang=js&"
import style0 from "./ClanPage.vue?vue&type=style&index=0&id=669a6af8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@5.2.7_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "669a6af8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('669a6af8')) {
      api.createRecord('669a6af8', component.options)
    } else {
      api.reload('669a6af8', component.options)
    }
    module.hot.accept("./ClanPage.vue?vue&type=template&id=669a6af8&scoped=true&", function () {
      api.rerender('669a6af8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/pages/ClanPage.vue"
export default component.exports