var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentWar && _vm.currentWar.opponent
    ? _c(
        "section",
        [
          _c("div", { staticClass: "tools" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column has-text-centered" }, [
                _c("div", { staticClass: "columns is-mobile is-inline-flex" }, [
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "media-left" }, [
                        _c("span", { staticClass: "icon is-medium" }, [
                          _c("img", {
                            attrs: { src: _vm.currentWar.clan.badgeUrls.small },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-content has-text-right" },
                        [
                          _c("div", [
                            _c("h3", { staticClass: "title is-6" }, [
                              _vm._v(_vm._s(_vm.currentWar.clan.name)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("b-icon", {
                                attrs: { pack: "fa", icon: "star" },
                              }),
                              _vm._v(" " + _vm._s(_vm.currentWar.clan.stars)),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "column is-narrow has-text-weight-light is-italic",
                    },
                    [_vm._v("vs")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column is-narrow" }, [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "media-left" }, [
                        _c("span", { staticClass: "icon is-medium" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.currentWar.opponent.badgeUrls.small,
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-content has-text-left" },
                        [
                          _c("div", [
                            _c("h3", { staticClass: "title is-6" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.currentWar.opponent.slug
                                      ? "/clan/" + _vm.currentWar.opponent.slug
                                      : "/goto/" +
                                        encodeURIComponent(
                                          _vm.currentWar.opponent.tag
                                        ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.currentWar.opponent.name) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("b-icon", {
                                attrs: { pack: "fa", icon: "star" },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.currentWar.opponent.stars)
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-narrow" },
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        scrollable: "",
                        "aria-role": "list",
                        triggers: ["hover"],
                        position: "is-bottom-left",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function () {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "is-danger",
                                      "icon-right": "fa fa-angle-down",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " vs " +
                                        _vm._s(_vm.currentWar.opponent.name) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1831520163
                      ),
                      model: {
                        value: _vm.currentWar,
                        callback: function ($$v) {
                          _vm.currentWar = $$v
                        },
                        expression: "currentWar",
                      },
                    },
                    [
                      _vm._v(" "),
                      _vm._l(_vm.clan.wars, function (war, index) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: index,
                            attrs: { value: war, "aria-role": "listitem" },
                          },
                          [
                            _c("div", { staticClass: "media" }, [
                              _c("div", { staticClass: "media-left" }, [
                                _c("span", { staticClass: "icon is-medium" }, [
                                  _c("img", {
                                    attrs: {
                                      src: war.opponent.badgeUrls.small,
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "media-content" }, [
                                _c("h3", [
                                  _vm._v("vs " + _vm._s(war.opponent.name)),
                                ]),
                                _vm._v(" "),
                                war.state == "preparation"
                                  ? _c("small", [
                                      _vm._v(
                                        " Starting in " +
                                          _vm._s(
                                            _vm._f("formatDistance")(
                                              war.startTime,
                                              false
                                            )
                                          )
                                      ),
                                    ])
                                  : war.state == "inWar"
                                  ? _c("small", [
                                      _vm._v(
                                        " Ending in " +
                                          _vm._s(
                                            _vm._f("formatDistance")(
                                              war.endTime,
                                              false
                                            )
                                          )
                                      ),
                                    ])
                                  : _c("small", [
                                      _vm._v(
                                        " Ended " +
                                          _vm._s(
                                            _vm._f("formatDistance")(
                                              war.endTime
                                            )
                                          )
                                      ),
                                    ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.currentWar
            ? _c(
                "b-table",
                {
                  ref: "table",
                  attrs: {
                    striped: "",
                    "mobile-cards": "",
                    data: _vm.currentWar.aggregated,
                  },
                },
                [
                  _c("b-table-column", {
                    attrs: { field: "name", label: "Name", sortable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " + _vm._s(props.row.name) + "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      984597786
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: { field: "tag", label: "Tag", sortable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " + _vm._s(props.row.tag) + "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2676559887
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "attack1__stars",
                      label: "First Attack Stars",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                { class: "stars_" + props.row.attack1__stars },
                                [
                                  props.row.attack1__stars != "na"
                                    ? _c("b-rate", {
                                        attrs: {
                                          "icon-pack": "fa",
                                          icon: "star",
                                          max: 3,
                                          disabled: "",
                                        },
                                        model: {
                                          value: props.row.attack1__stars,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.row,
                                              "attack1__stars",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row.attack1__stars",
                                        },
                                      })
                                    : _c("span", [_vm._v("—")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2493206006
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "attack1__destructionPercentage",
                      label: "First Attack Destruction",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    props.row.attack1__destructionPercentage !=
                                      "na"
                                      ? Number(
                                          props.row
                                            .attack1__destructionPercentage /
                                            100
                                        ).toLocaleString(undefined, {
                                          style: "percent",
                                          minimumFractionDigits: 0,
                                        })
                                      : "—"
                                  ) +
                                  "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2369461302
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "attack2__stars",
                      label: "Second Attack Stars",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                { class: "stars_" + props.row.attack2__stars },
                                [
                                  props.row.attack2__stars != "na"
                                    ? _c("b-rate", {
                                        attrs: {
                                          "icon-pack": "fa",
                                          icon: "star",
                                          max: 3,
                                          disabled: "",
                                        },
                                        model: {
                                          value: props.row.attack2__stars,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.row,
                                              "attack2__stars",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row.attack2__stars",
                                        },
                                      })
                                    : _c("span", [_vm._v("—")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      819358997
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "attack2__destructionPercentage",
                      label: "Second Attack Destruction",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    props.row.attack2__destructionPercentage !=
                                      "na"
                                      ? Number(
                                          props.row
                                            .attack2__destructionPercentage /
                                            100
                                        ).toLocaleString(undefined, {
                                          style: "percent",
                                          minimumFractionDigits: 0,
                                        })
                                      : "—"
                                  ) +
                                  "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4113826166
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "opponentAttacks",
                      label: "Opponent Attacks",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    props.row.opponentAttacks != "na"
                                      ? props.row.opponentAttacks
                                      : "—"
                                  ) +
                                  "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      264073147
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "bestOpponentAttack__stars",
                      label: "Best Opponent Stars",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    "stars_" +
                                    props.row.bestOpponentAttack__stars,
                                },
                                [
                                  props.row.bestOpponentAttack__stars != "na"
                                    ? _c("b-rate", {
                                        attrs: {
                                          "icon-pack": "fa",
                                          icon: "star",
                                          max: 3,
                                          disabled: "",
                                        },
                                        model: {
                                          value:
                                            props.row.bestOpponentAttack__stars,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              props.row,
                                              "bestOpponentAttack__stars",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "props.row.bestOpponentAttack__stars",
                                        },
                                      })
                                    : _c("span", [_vm._v("—")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1670100566
                    ),
                  }),
                  _vm._v(" "),
                  _c("b-table-column", {
                    attrs: {
                      field: "bestOpponentAttack__destructionPercentage",
                      label: "Best Opponent Destruction",
                      numeric: "",
                      sortable: "",
                      centered: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._v(
                                "\n      " +
                                  _vm._s(
                                    props.row
                                      .bestOpponentAttack__destructionPercentage !=
                                      "na"
                                      ? Number(
                                          props.row
                                            .bestOpponentAttack__destructionPercentage /
                                            100
                                        ).toLocaleString(undefined, {
                                          style: "percent",
                                          minimumFractionDigits: 0,
                                        })
                                      : "—"
                                  ) +
                                  "\n    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2751748406
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }