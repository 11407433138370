var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "columns is-mobile is-centered-mobile" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ])
    : _c("time", { staticClass: "is-italic" }, [
        _vm._v("Updated " + _vm._s(_vm.text)),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("div", { staticClass: "lds-ring mr-1 has-text-right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("span", { staticClass: "loading is-italic" }, [_vm._v("updating")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }